import { useEffect, useCallback } from "react";

function useAutoLogout({ timeout, onTimeOut, user }) {
  const logout = useCallback(() => {
    if (user?.signInUserSession) {
      onTimeOut && onTimeOut();
    }
  }, [onTimeOut, user]);

  useEffect(() => {
    let logoutTimer;

    const setTimeouts = () => {
      logoutTimer = setTimeout(logout, timeout);
    };

    const clearTimeouts = () => {
      if (logoutTimer) clearTimeout(logoutTimer);
    };
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
}

export default useAutoLogout;
