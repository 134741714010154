import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from "assets/images/Craftn.png";
import { Image } from "..";
import { userSrvc } from 'services';

const nav = [
    {
        name: 'Banner',
        path: "/banner",
        id: 'banner123'
    },
    {
        name: 'Category',
        path: "/category",
        id: 'category123'
    },
    {
        name: 'Product',
        path: "/product",
        id: 'product123'
    },
]

const Sidebar = ({ sidebarRef, isOpen }) => {
    const [navItems, setNavItems] = useState(nav || []);

    useEffect(() => {
        async function getNavData() {
            const result = await userSrvc.getNavData();
            if (result.status === 'SUCCESS') {
                setNavItems(result.data);
            }
        }
        try {
            let val = localStorage.getItem("accessToken");
            if (val !== "undefined") {
                const accessToken = JSON.parse(val);
                if (accessToken) {
                    getNavData();
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [])



    if (!navItems) return <h1>Loading...</h1>

    return (
        <div className="sidebar h-screen overflow-hidden bg-stone-100 border br-stone-200 p-5" ref={sidebarRef}>
            <Link to="/">
                <div className='flex items-center' >
                    <Image src={logo} className="h-8 w-8" />
                    {isOpen && <h1 className='font-bold text-lg ml-4'>Craftn</h1>}
                </div>
            </Link>
            {navItems && <ul className="p-5">
                {
                    navItems.map((itm) => {
                        return (
                            <li key={itm.id} className={`py-2 hover:text-primary hover: cursor-pointer`}>
                                <NavLink to={itm.path} className={({ isActive }) => isActive ? 'text-primary' : ''}>{itm.name}</NavLink>
                            </li>
                        )
                    })
                }
            </ul>}
        </div>
    );
}

export default Sidebar;