import React, { createContext, useEffect, useState } from "react";
import { userSrvc } from "services";
import { useAutoLogout } from "hooks";
import EventEmitter from "services/eventEmitter";
import Modal from "components/Modal";
import InactivityModal from "components/InactivityModal";
// logout timeout  = minutes * seconds * milliseconds - 30 seconds
const timeOut = process.env.REACT_APP_LOGOUT_TIMEOUT * 60 * 1000 - 30 * 1000;


let AuthContext = createContext(null);


function AuthProvider({ children }) {
  let [user, setUser] = useState({});
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  useEffect(() => {
    EventEmitter.subscribe('onUpdateAccessToken', (data) => {
      console.log("on update")
      localStorage.setItem("user", JSON.stringify({ "accessToken": data.accessToken }))
      setUser(prevUser => {
        return {
          ...prevUser,
          accessToken: data.accessToken
        }
      })
    })

    return () => EventEmitter.unsubscribe('onUpdateAccessToken');
  }, [])

  useEffect(() => {
    async function getUser() {
      try {
        let val = localStorage.getItem("accessToken");
        if (val !== null) {
          const user = await userSrvc.getUser(JSON.parse(val));
          setUser(user.data);
        }
      } catch (e) {
        console.log(e);
      }
    }
    getUser();
  }, []);

  let updateUser = function (data) {
    setUser(data)
  }

  function logoutPrompt() {
    if (user?.signInUserSession) {
      setLogoutModalOpen(true);
    }
  }

  useAutoLogout({
    timeout: timeOut,
    onTimeOut: logoutPrompt,
    user,
  });

  let value = {
    user,
    updateUser
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      <Modal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        size="sm"
      >
        <InactivityModal
          title="Session will Expire Soon!"
          desc="You will be automatically logged out for security reasons in:"
          setLogoutModalOpen={setLogoutModalOpen}
        />
      </Modal>
    </AuthContext.Provider>
  );
}

export {
  AuthContext,
  AuthProvider,
}
