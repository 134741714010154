import { Backdrop } from "components";
import React from "react";
import ReactDom from "react-dom";
// import cross from "assets/images/close.svg";

export default function Modal({
  open,
  children,
  onClose,
}) {
  if (!open) return null;

  let mainClass = `modal lg:lg`;

  return ReactDom.createPortal(
    <>
      <Backdrop onClose={onClose} />
      <div className={mainClass}>
        {/* <img alt="Close" onClick={onClose} src={cross} className="close-icon" /> */}
        {children}
      </div>
    </>,
    document.getElementById("modal")
  );
}
