import { endPoints } from "./endPoints";
import http from "./httpSrvc";
// TODO: need to remove & integrate with actual api

// const fakeData = [
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
//   {
//     image: 'https://rukminim1.flixcart.com/image/714/857/l2arp8w0/t-shirt/c/w/9/xs-tlorrn-d45-tripr-original-imagdzex5gg6dahf.jpeg?q=50',
//     actualPrice: '$99.99',
//     offerPrice: '$79.9',
//     discount: '12%'
//   },
// ]
async function getProducts(limit, offset) {

  try {
    const response = await http.get(endPoints.GET_PRODUCTS);
    let result = {
      status: "SUCCESS",
      data: response,
    };
    return result;
  } catch (err) {
    let result = {
      status: "ERROR",
      data: err,
    };
    return result;
  }
}

export default { getProducts };
