import { endPoints } from "./endPoints";
import http from "./httpSrvc";

async function createBanner(payload) {
    try {
        const res = await http.post(endPoints.BANNERS, payload);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function getBanners() {
    try {
        const res = await http.get(endPoints.BANNERS);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function deleteBanner(bannerId) {
    try {
        const res = await http.delete(`${endPoints.BANNERS}/${bannerId}`);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

export default { createBanner, getBanners, deleteBanner };