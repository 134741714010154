import React from "react";

// Form
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Atoms
import { Button } from "components";

// Molecule
import { FormInput } from "components";


const initialValues = { username: "", password: "" };

/* eslint-disable no-useless-escape */
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const passRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Email / Phone Number is Required"),
  password: Yup.string().required(),
});

const LoginForm = ({ handleSubmit, loading, error }) => {

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={LoginSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          isSubmitting,
          isValid,
          values,
          touched,
          handleChange,
          handleBlur,
        }) => {
          return (
            <Form>
              <h1 className="mb-4 font-bold">Login</h1>
              {error.message && (
                <div
                  className="text-red-600 mb-4 ml-1 text-center"
                >
                  {error.message}
                </div>
              )}
              <Field
                name="username"
                placeholder="Email / Phone Number"
                touched={touched}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                className="w-full focus:outline-none"
              />
              <Field
                type="password"
                name="password"
                placeholder="Password"
                touched={touched}
                component={FormInput}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                autoComplete="false"
                className="w-full  focus:outline-none"
              />

              <Button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md p-2 px-4 xl w-full focus:outline-none"
                varient="primary"
                block
              >
                {loading ? 'Loading...' : 'Login'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
};

export default LoginForm;
