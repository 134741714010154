import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "components";
import { Button } from "components";

// Context
import { useIsMounted, useAuth } from "hooks";

const InactivityModal = ({ title, desc, setLogoutModalOpen }) => {
  const [count, setCount] = useState(
    60 * 60
  );
  const auth = useAuth();
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const onAfterLogout = useCallback(() => {
    if (isMounted()) {
      setLogoutModalOpen(false);
    }
    navigate("/login");
  }, [isMounted, navigate, setLogoutModalOpen]);

  const logout = useCallback(() => {
    auth.signout(onAfterLogout);
  }, [auth, onAfterLogout]);

  useEffect(() => {
    let timer;
    clearInterval(timer);
    timer = setInterval(() => {
      if (count === 1) {
        timer && clearInterval(timer);
        logout();
      } else {
        setCount(count - 1);
      }
    }, 1000);
    return () => {
      timer && clearInterval(timer);
    };
  }, [count, logout]);

  return (
    <div>
      <Text className="font-normal mb-2" fontSize={32}>
        {title}
      </Text>
      <Text fontSize={20} fontColor="gray">
        {desc}
      </Text>
      <Text className="font-normal mt-4" fontSize={20}>
        {count} {count > 1 ? "Seconds" : "Second"}
      </Text>
      <div className="flex flex-col justify-end mt-5 lg:flex-row">
        <Button
          varient="white"
          className="m-3 py-2 px-4 w-auto border border-black lg:w-30"
          onClick={logout}
        >
          LOGOUT
        </Button>
        <Button
          varient="primary"
          className="m-3 py-2 px-4 w-auto lg:w-30"
          onClick={() => setLogoutModalOpen(false)}
        >
          STAY CONNECTED
        </Button>
      </div>
    </div>
  );
};

export default InactivityModal;
