import React from "react";

const Card = ({ children, className = "", ...props }) => {
  return (
    <div {...props} className={`border border-solid border-stone-200 rounded-md pb-4 inline-block ${className}`}>
      {children}
    </div>
  );
};

export default Card;
