import React from 'react'
import { ReactSVG } from 'react-svg'

const ReactSvg = ({src, isSelected, props}) => {
  return (
    <ReactSVG
      src={src}
      beforeInjection={(svg) => {
        if(isSelected) {
          const paths = [...svg.querySelectorAll('path')]
          paths.map((item) => item.setAttribute('fill', 'white'))
        }
      }}
      fallback={() => <span>Error!</span>}
      {...props}
    />
  )
}

export default ReactSvg
