import React from 'react';
import { Link } from 'react-router-dom';


const features = [
    {
        title: 'Product',
        link: '/product'
    },
    {
        title: 'Banner',
        link: '/banner'
    },
    {
        title: 'Category',
        link: '/category'
    }
]

const FeatureGrid = () => {
    return (

        <div className="w-full grid grid-cols-5 gap-4 p-5">
            {features && features.map((itm, index) => {
                return (
                    <div className='border border-solid border-zinc-200 p-5 relative cursor-pointer' key={`feature-${index}`}>
                        <div className='center'>
                            <Link to={itm.link}>{itm.title}</Link>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default FeatureGrid;