// import useAxiosPrivate from "hooks/useAxiosPrivate";
import { endPoints } from "./endPoints";
import http from './httpSrvc';
// import { axiosPrivate } from "./httpSrvc";

async function getUser(token) {
    // const axiosPrivate = useAxiosPrivate();
    try {
        const response = await http.get(endPoints.GET_USER, { accessToken: token });
        let result = {
            status: "SUCCESS",
            data: response,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err?.data,
        };
        return result;
    }
}

async function getUsers(token) {
    // const axiosPrivate = useAxiosPrivate();
    try {
        const response = await http.get(`${endPoints.GET_USERS}?roles=["admin","seller"]`, { accessToken: token });
        let result = {
            status: "SUCCESS",
            data: response,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err?.data,
        };
        return result;
    }
}

async function updateUser(payload) {
    // const axiosPrivate = useAxiosPrivate();
    try {
        const response = await http.put(endPoints.GET_USER, payload);
        let result = {
            status: "SUCCESS",
            data: response.data,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err?.data,
        };
        return result;
    }
}

async function getNavData() {
    // const axiosPrivate = useAxiosPrivate();
    try {
        const response = await http.get(endPoints.NAV_DATA);
        let result = {
            status: "SUCCESS",
            data: response,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err?.data,
        };
        return result;
    }
}

async function createUser(values) {
    try {
        const response = await http.post(endPoints.CREATE_USER, values);
        let result = {
            status: "SUCCESS",
            data: response,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err?.data,
        };
        return result;
    }
}


export default {
    getUser,
    updateUser,
    getNavData,
    getUsers,
    createUser
}
