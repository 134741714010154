import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import { AuthProvider } from "context";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthLayout, DashboardLayout } from "layouts";

const Home = lazy(() => import("pages/Home"));
const Products = lazy(() => import("pages/Products"))
const Login = lazy(() => import("pages/Login"));
const Signup = lazy(() => import("pages/Signup"));
const PageNotFound = lazy(() => import("pages/404"));
const Profile = lazy(() => import("pages/Profile"));
const Category = lazy(() => import("pages/Category"));
const Banner = lazy(() => import("pages/Banner"));
const Templates = lazy(() => import("pages/Templates"));
const CreateTemplate = lazy(() => import("pages/CreateTemplate"));
const CreatePage = lazy(() => import("pages/CreatePage"));
const Manageuser = lazy(() => import("pages/ManageUser"));

const RequireAuth = ({ allowedRoles }) => {

  const location = useLocation();
  let accessToken = null;

  try {
    let val = localStorage.getItem("accessToken");
    if (val !== "undefined") {
      accessToken = JSON.parse(val);
    }
  } catch (e) {
    console.log(e);
  }

  return (
    accessToken !== null
      ? <Outlet />
      : <Navigate to="/login" state={{ from: location }} replace />
  );
}

const App = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<AuthLayout><Login /></AuthLayout>} />
            <Route path="/signup" element={<AuthLayout><Signup /></AuthLayout>} />
            <Route path="/" element={<DashboardLayout />}>
              <Route element={<RequireAuth allowedRoles={[]} />}>
                <Route path="/" element={<Home />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/banner" element={<Banner />} />
                <Route path="/product" element={<Products />} />
                <Route path="/category" element={<Category />} />
                <Route path="/templates" element={<Templates />} />
                <Route path="/create-template" element={<CreateTemplate />} />
                <Route path="/create-page" element={<CreatePage />} />
                <Route path="/manage-user" element={<Manageuser />} />
              </Route>
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </AuthProvider>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
