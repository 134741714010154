import { endPoints } from "./endPoints";
import http from "./httpSrvc";

async function createCategory(payload) {
    try {
        const res = await http.post(endPoints.CATEGORIES, payload);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function getCategories() {
    try {
        const res = await http.get(endPoints.CATEGORIES);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function deleteCategory(categoryId) {
    try {
        const res = await http.delete(`${endPoints.CATEGORIES}/${categoryId}`);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

export default { createCategory, getCategories, deleteCategory };