import React from "react";

const Image = ({ className = "", src, alt, width, height, ...props }) => {
  return (
    <img
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={`${height}px`}
      {...props}
    />
  );
};

export default Image;
