import { endPoints } from "./endPoints";
import http from "./httpSrvc";

async function createProduct(payload) {
    try {
        const res = await http.post(endPoints.PRODUCTS, payload);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function getProducts(params) {
    try {
        let query = new URLSearchParams(params)
        const res = await http.get(`${endPoints.PRODUCTS}?${query}`);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function getFilters() {
    try {
        const res = await http.get(`${endPoints.PRODUCTS}/filters`);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function deleteProduct(productId) {
    try {
        const res = await http.delete(`${endPoints.PRODUCTS}/${productId}`);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

export default { createProduct, getProducts, deleteProduct, getFilters };