import React from 'react'

const sizes = {
  'sm': 'py-1 px-4',
  'md': 'py-2 px-4',
  'lg': 'py-3 px-6'
}


//test
const Button = ({
  type,
  disabled,
  onClick,
  children,
  block,
  varient,
  size,
  className
}) => {
  let mainClass;

  if (varient === 'primary') {
    mainClass = `bg-primary px-4 py-1 ${block ? 'w-full' : ''} ${sizes[size]} rounded-sm text-white ${disabled ? 'bg-primary-disabled' : ''}`
  } else {
    mainClass = `border border-primary px-4 py-1 ${block ? 'w-full' : ''} ${sizes[size]} rounded-sm text-primary`
  }

  return (
    <button
      type={type}
      className={`${mainClass} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
