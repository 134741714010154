import React from "react";
import logo from "assets/images/Craftn.png";
import SignupInit from "./SignupInit";
import SignupComplete from "./SignupComplete";


export const SignupForm = ({ handleSubmit, loading, signupState, phone }) => {

  return (
    <div>
      <img src={logo} alt="logo" className="block mb-5 mx-auto h-12" />
      <div className="mb-4 font-bold">Sign Up</div>
      {signupState === 'SIGNUP_INIT' ? <SignupInit handleSubmit={handleSubmit} loading={loading} phone={phone} /> : <SignupComplete handleSubmit={handleSubmit} loading={loading} formState={{ phone, otp: '', password: '' }} />}
    </div>
  )
};
