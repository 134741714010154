import { useCallback, useLayoutEffect, useEffect, useRef } from 'react'

const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

export function useIsMounted() {
  const isMountedRef = useRef(true)
  const isMounted = useCallback(() => isMountedRef.current, [])

  useEnhancedEffect(() => {
    return () => void (isMountedRef.current = false)
  }, [])

  return isMounted
}
