import { endPoints } from "./endPoints";
import http from "./httpSrvc";

async function signupInit(payload) {
  try {
    const res = await http.post(endPoints.SIGNUP_INIT, payload);
    let result = {
      status: "SUCCESS",
      data: res,
    };
    return result;
  } catch (err) {
    let result = {
      status: "ERROR",
      data: err,
    };
    return result;
  }
}

async function signupComplete(payload) {
  try {
    const res = await http.post(endPoints.SIGNUP_COMPLETE, payload);
    let result = {
      status: "SUCCESS",
      data: res,
    };
    return result;
  } catch (err) {
    let result = {
      status: "ERROR",
      data: err,
    };
    return result;
  }
}

async function directLogin(payload) {
  try {
    const res = await http.post(endPoints.DIRECT_LOGIN, payload);
    let result = {
      status: "SUCCESS",
      data: res,
    };
    return result;
  } catch (err) {
    let result = {
      status: "ERROR",
      data: err,
    };
    return result;
  }
}

async function loginInit(payload) {
  try {
    const res = await http.post(endPoints.LOGIN_INIT, payload);
    let result = {
      status: "SUCCESS",
      data: res,
    };
    return result;
  } catch (err) {
    let result = {
      status: "ERROR",
      data: err,
    };
    return result;
  }
}

async function loginComplete(payload) {
  try {
    const res = await http.post(endPoints.LOGIN_COMPLETE, payload);
    let result = {
      status: "SUCCESS",
      data: res,
    };
    return result;
  } catch (err) {
    let result = {
      status: "ERROR",
      data: err,
    };
    return result;
  }
}

async function logOut() {
  try {
    const response = await http.get(endPoints.LOGOUT);
    let result = {
      status: "SUCCESS",
      data: response,
    };
    return result;
  } catch (err) {
    let result = {
      status: "ERROR",
      data: err,
    };
    return result;
  }
}

export default {
  signupInit,
  signupComplete,
  directLogin,
  loginInit,
  loginComplete,
  logOut,
};
