import React from 'react';
import { Link } from "react-router-dom";

// Form
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Atoms
import { Button } from "components";

// Molecule
import { FormInput } from "components";

const SignupSchema = Yup.object().shape({
    phone: Yup.string().required("Phone Number is Required"),
    otp: Yup.string().required("OTP is Required"),
    password: Yup.string().required("Password is Required"),
});

const SignupComplete = ({ handleSubmit, formState, loading, error, loginState }) => {
    return (
        <>
            <Formik
                initialValues={formState}
                onSubmit={handleSubmit}
                validationSchema={SignupSchema}
            >
                {({
                    isSubmitting,
                    isValid,
                    values,
                    touched,
                }) => (
                    <Form>
                        {error && error.message && (
                            <div
                                className="text-red-600 mb-4 ml-1 text-center"
                            >
                                {error.message}
                            </div>
                        )}
                        <Field
                            type="text"
                            name="phone"
                            placeholder="Phone Number"
                            touched={touched}
                            component={FormInput}
                            value={values.phone}
                            className="w-full focus:outline-none"
                            disabled={true}
                        />
                        <Field
                            type="text"
                            name="otp"
                            placeholder="OTP"
                            touched={touched}
                            component={FormInput}
                            value={values.otp}
                            className="w-full focus:outline-none"
                        />
                        <Field
                            type="password"
                            name="password"
                            placeholder={loginState && loginState === 'FORGOT_PASS' ? 'Set New Password' : 'Create Password'}
                            touched={touched}
                            component={FormInput}
                            value={values.password}
                            className="w-full focus:outline-none"
                        />
                        <Button
                            type="submit"
                            disabled={isSubmitting || loading}
                            className="block rounded-md p-2 px-4 xl w-full focus:outline-none"
                            varient="primary"
                            block
                        >
                            {loading ? 'Loading...' : `${loginState && loginState === 'FORGOT_PASS' ? 'Reset' : 'Sign Up'}`}
                        </Button>
                        <div className="my-4 ml-1 text-center" fontSize="xs">
                            have Account ?
                            <Link className="text-blue-800" to="/login">
                                {' Login'}
                            </Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default SignupComplete;