import { endPoints } from "./endPoints";
import http from "./httpSrvc";

async function createTemplate(payload) {
    try {
        const res = await http.post(`${endPoints.PAGE}/curate`, payload);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function getTemplates() {
    try {
        const res = await http.get(`${endPoints.PAGE}`);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

async function getTemplate(templateId) {
    try {
        const res = await http.get(`${endPoints.PAGE}/${templateId}`);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}


async function deleteTemplate(template) {
    try {
        const res = await http.delete(`${endPoints.PAGE}/${template}`);
        let result = {
            status: "SUCCESS",
            data: res,
        };
        return result;
    } catch (err) {
        let result = {
            status: "ERROR",
            data: err,
        };
        return result;
    }
}

export default {
    createTemplate,
    getTemplates,
    getTemplate,
    deleteTemplate
}