const endPoints = {
    SIGNUP_INIT: "/auth/register/init",
    SIGNUP_COMPLETE: "/auth/register/complete",
    DIRECT_LOGIN: "/auth/login/direct",
    LOGIN_INIT: "/auth/login/init",
    LOGIN_COMPLETE: "/auth/login/complete",
    LOGOUT: "/auth/logout",
    GET_USER: "/user",
    GET_USERS: "/user/all",
    GET_PRODUCTS: "/products",
    CATEGORIES: '/categories',
    PRODUCTS: "/products",
    BANNERS: "/banners",
    PAGE: "/page",
    NAV_DATA: "/user/nav",
    CREATE_USER: "/auth/create-user",
}

export { endPoints };