import React from 'react';
import { Link } from "react-router-dom";

// Form
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Atoms
import { Button } from "components";

// Molecule
import { FormInput } from "components";


const SignupSchema = Yup.object().shape({
    phone: Yup.string().required("Phone Number is Required"),
});

const SignupInit = ({ handleSubmit, loading, phone, error }) => {
    return (
        <>
            <Formik
                initialValues={{ phone }}
                onSubmit={handleSubmit}
                validationSchema={SignupSchema}
            >
                {({
                    isSubmitting,
                    isValid,
                    values,
                    touched,
                }) => (
                    <Form>
                        {error && error?.message && (
                            <div
                                className="text-red-600 mb-4 ml-1 text-center"
                            >
                                {error.message}
                            </div>
                        )}
                        <Field
                            type="text"
                            name="phone"
                            placeholder="Phone Number"
                            touched={touched}
                            component={FormInput}
                            value={values.phone}
                            className="w-full focus:outline-none"
                        />

                        <Button
                            type="submit"
                            disabled={isSubmitting || loading}
                            className="block rounded-md p-2 px-4 xl w-full focus:outline-none"
                            varient="primary"
                            block
                        >
                            {loading ? 'Loading...' : 'Continue'}
                        </Button>
                        <div className="my-4 ml-1 text-center">
                            have Account ?
                            <Link className="text-blue-800" to="/login">
                                {' Login'}
                            </Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default SignupInit;