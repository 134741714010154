import React, { useEffect, useState, useRef, useCallback } from "react";

// Organisms
import { Header, Main, Footer } from "components";

// Context
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "hooks";
import EventEmitter from "services/eventEmitter";
import { authSrvc } from "services";
import Sidebar from "components/Sidebar";



const HomeLayout = ({ children }) => {
  const { user, updateUser } = useAuth();
  const [isOpen, setIsOpen] = useState(true);
  const sidebarRef = useRef();
  const navigate = useNavigate();

  function toggleSidebar() {
    if (isOpen) {
      sidebarRef.current.style.cssText = "width:5%;"
      setIsOpen(false)
    } else {
      sidebarRef.current.style.cssText = "width:15%;"
      setIsOpen(true)
    }
  }

  const logout = useCallback(async () => {
    const res = await authSrvc.logOut();
    if (res) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('accessTokenExpiryTime')
      updateUser({});
      navigate("/login")
    }
  }, [navigate, updateUser])

  useEffect(() => {
    EventEmitter.subscribe('onTokenExpiry', (data) => {
      logout();
    })
    return () => EventEmitter.unsubscribe('onTokenExpiry');
  }, [logout])

  return (
    <>
      <div className="flex">
        <Sidebar sidebarRef={sidebarRef} isOpen={isOpen} />
        <div className="content" id="content">
          <Header name={user?.data?.email || "Alien"} logout={logout} toggleSidebar={toggleSidebar} />
          <Main>
            <Outlet />
            <Footer />
          </Main>
        </div>
      </div>
    </>
  );
};

export default HomeLayout;
