import React from "react";

const Radio = ({ field, form: { touched, errors }, ...props }) => {

  return (
    <div className={`flex items-center mr-2 mb-5`}>
      <label className={`cursor-pointer whitespace-nowrap text-bl-black text-sm font-medium flex`} >
        <input
          {...field}
          {...props}
          error={errors[field.name] && touched[field.name]}
        />
        <span className={`flex justify-center items-center h-5 w-5 border border-border-primary rounded-full`}>
          <span className={`h-3 rounded-full w-3 block ${props.checked ? 'bg-primary' : ''}`}></span>
        </span>
        <span className="ml-2">{field.value}</span>
      </label>
      {errors[field.name] && touched[field.name] && (
        <div
          className="text-xs text-red-600 mb-4 ml-1"
        >
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default Radio;
