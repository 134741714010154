import axios from "axios";
import EventEmitter from "./eventEmitter";

/**
 * This represents some generic http service
 */

const defaults = {
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://localhost:8080/api",
  headers: () => {
    let config = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
    try {
      let val = localStorage.getItem("user");
      if (val !== 'undefined') {
        let user = JSON.parse(val);
        if (user?.accessToken) {
          config['Authorization'] = `Bearer ${user.accessToken}`
        }
      }
      return config;
    } catch (e) {
      console.log(e);
    }
  },
  error: {
    code: "INTERNAL_ERROR",
    message:
      "Something went wrong. Please check your internet connection or contact our support.",
    status: 503,
    data: {},
  },
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://localhost:8080/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true
})

const getAccessToken = () => {
  try {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken !== 'undefined') {
      return JSON.parse(accessToken);
    }
  } catch (e) {
    console.log(e);
  }
}

const refresh = async () => {
  const instanceTwo = axios.create({ baseURL: defaults.baseURL });
  const response = await instanceTwo.get('/auth/refresh', { withCredentials: true });
  EventEmitter.emit('onUpdateAccessToken', { accessToken: response.data.accessToken })
  return response.data.accessToken;
}

const api = async (method, url, data, config) => {

  const accessToken = config?.accessToken || getAccessToken();

  const requestIntercept = instance.interceptors.request.use(
    config => {
      if (!config.headers['Authorization']) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    }, (error) => Promise.reject(error)
  );

  const responseIntercept = instance.interceptors.response.use(
    response => response,
    async (error) => {
      const prevRequest = error?.config;
      // if (error?.response?.status === 403 && !prevRequest?.sent) {
      //   prevRequest.sent = true;
      //   const newAccessToken = await refresh();
      //   prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      //   return instance(prevRequest);
      // }
      return Promise.reject(error);
    }
  );

  try {
    let response;
    if (method === 'get' || method === 'delete') {
      response = await instance[method](url, config);
    } else {
      response = await instance[method](url, data, config)
    }
    return response.data
  } catch (error) {
    if (error?.response?.data) {
      if (error?.response?.data?.message === "Token Expired, Please login to Continue") {
        EventEmitter.emit("onTokenExpiry", { message: error?.response?.data?.message })
      }
      throw error?.response?.data
    }
    throw error;
  }
};

function fakeApi(data) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 2000)
  })
}

const http = {
  get: (url, config) => api('get', url, null, config),
  post: (url, data, config) => api('post', url, data, config),
  put: (url, data, config) => api('put', url, data, config),
  patch: (url, data, config) => api('patch', url, data, config),
  delete: (url, config) => api('delete', url, null, config),
  fakeApi
}

export default http;

