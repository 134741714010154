import React from "react";
import logo from "assets/images/Craftn.png";
//Atoms
import { Image, Links } from "..";

export const Footer = () => {
  const links = ["Facebook", "Instagram"];
  return (
    <footer className="w-full z-60 flex-shrink-0">
      <div className="border-t flex flex-col items-start bg-white p-4 lg:flex lg:flex-row lg:items-center lg:justify-between lg:pl-16 lg:pr-16">
        <div className="flex items-center justify-between w-full lg:w-auto lg:pb-0  ">
          <div className="flex items-center">
            <p className="font-normal font-Futura-PT text-xsm text-bl-gray capitalize ml-5">
              {"Craftn 2022. All Rights Reserved."}
            </p>
          </div>
          <div className="-mt-1 pl-0 lg:pl-4">
            {links.map((linkItem, index) => {
              return (
                <Links
                  key={index}
                  className="text-12 pl-5"
                >
                  {linkItem}
                </Links>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
