import React, { useRef, useState } from 'react';

import { useAuth, useOutsideAlerter } from 'hooks';
import { Link } from 'react-router-dom';

const Header = ({ logout, toggleSidebar }) => {
    const { user } = useAuth();
    const profileDropdownRef = useRef();
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    // useOutsideAlerter(profileDropdownRef, setShowProfileMenu);

    console.log('user', user);

    return (
        <div className='relative z-10'>
            <div className='bg-white border-b sticky p-2 left-0 top-0 right-0'>
                <div className='flex items-center justify-between relative'>
                    <div className='flex items-center'>
                        {/* <div onClick={toggleSidebar}>
                            <i className="fa-solid fa-bars flex justify-center items-center hover:bg-gray-100 h-10 w-10 rounded-md p-2 cursor-pointer"></i>
                        </div> */}
                        <div className='relative inline px-2'>
                            <input id="search" type="search" className='border border-border-primary py-2 pl-10 rounded-md outline-none focus:bg-white bg-gray-100 text-sm h-full' placeholder='Search for Crafts' />
                            <i className="fa-solid fa-search absolute left-4 top-2.5 text-primary"></i>
                        </div>
                    </div>
                    <div className='flex items-center justify-end'>

                        <div onClick={() => {
                            console.log("Called");
                            setShowProfileMenu(!showProfileMenu)
                        }} className="ml-5 relative">
                            <i className="fa-solid fa-user flex justify-center items-center hover:bg-gray-100 h-10 w-10 rounded-md p-2 cursor-pointer"></i>
                            {/* <img class="inline-block h-12 w-12 rounded-full ring-2 ring-white" src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> */}
                            {showProfileMenu && <ul ref={profileDropdownRef} className='absolute right-0 top-11 bg-white py-2 w-36 rounded-md border border-1 border-gray-200'>
                                <li className='cursor-pointer hover:bg-gray-200 px-5'><Link to="/profile">{user?.firstName || user?.lastName || 'My Account'}</Link></li>
                                <li className='cursor-pointer hover:bg-gray-200 px-5' onClick={logout}>{user?.identifier ? 'Logout' : 'Login'}</li>
                            </ul>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Header;