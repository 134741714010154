// Atoms
import { Text, Input } from "components";


const FormInput = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <div className="mb-6">
      <Input
        {...field}
        {...props}
        error={errors[field.name] && touched[field.name]}
      />
      {errors[field.name] && touched[field.name] && (
        <div
          className="text-xs text-red-600 mb-4 ml-1"
        >
          {errors[field.name]}
        </div>
      )}
    </div>
  );
};

export default FormInput;
