import React from "react";

const ProfileIcon = ({ value, className = "", onClick }) => {
  return (
    <div
      className={`flex justify-center content-center rounded-full
      bg-bl-blue font-normal text-center text-white ${className}`}
      onClick={onClick}
    >
      {value
        .split(" ")
        .map((name) => name[0])
        .join("")
        .toUpperCase()}
    </div>
  );
};

export default ProfileIcon;
