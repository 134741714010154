import React from "react";

const Input = ({ className = "", error, ...props }) => {
  let { type } = props;
  if (type === 'text') {
    return (
      <div className={`relative z-0 w-full ${props.disabled ? 'bg-gray-200' : ''}`}>
        <input {...props} type="text" id="floating_standard" className="block pt-2.5 pb-1 px-0  w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer mb-2" placeholder=" " />
        <label htmlFor="floating_standard" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{props.placeholder}</label>
      </div>
    );
  }
  return (
    <input
      {...props}
      className={`border border-solid border-zinc-200 block rounded-md p-2 px-4 ${className} ${error ? "mb-2" : "mb-5"
        }`}
    />
  )
};

export default Input;
