import React from "react"; import { Navigate } from "react-router-dom";
;

const NoLayout = ({ children }) => {

  let accessToken;

  try {
    let val = localStorage.getItem("accessToken");
    if (val !== "undefined") {
      accessToken = JSON.parse(val);
    }
  } catch (e) {
    console.log(e);
  }

  if (accessToken) return <Navigate to="/" />

  return (
    <>
      {children}
    </>
  );
};

export default NoLayout;
